import { useStore } from "@nanostores/react";
import { useCallback } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { useTheme } from "@/contexts/theme.context";
import { $tickMessage } from "@/services/websocket";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { useLayoutContext } from "../layout/context";
import { useSignalContext } from "../signal/context";
import { useCurrentSymbolContext } from "../symbol-info/current-symbol-context";
import { Chart } from "./chart";
import { useChartContext } from "./context";
import { ChartLoader } from "./loader";

const Container: React.FC = () => {
  const tickMessage = useStore($tickMessage);
  const { symbolInfo } = useCurrentSymbolContext();
  const { isMobile, isChartExpanded, setIsChartExpanded, isMobileChartPage } = useLayoutContext();
  const { symbolsList } = useSymbolsContext();
  const { account } = useTerminalAccountContext();
  const { chartInfo, setChartInfo, lastInterval, chartDataIsLoaded } = useChartContext();
  const {
    hasSignal,
    openSignalForm: _openSignalForm,
    closeSignalChartDialog,
    isSignalChartDialogShown,
    chartLines,
    isTerminalTargetPricesEnabled,
    isSignalLinesShown,
    showSignalChartDialog,
  } = useSignalContext();

  const { isDarkTheme } = useTheme();

  const openSignalForm = useCallback(() => {
    track(amplitudeEvents.terminal.openTradeSignal, {
      signal_button_section: "terminal",
    });
    _openSignalForm();
  }, [_openSignalForm]);

  if (!chartDataIsLoaded) {
    return <ChartLoader />;
  }

  return (
    <Chart
      chartLines={chartLines}
      isTerminalTargetPricesEnabled={isTerminalTargetPricesEnabled}
      isSignalLinesShown={isSignalLinesShown}
      showSignalChartDialog={showSignalChartDialog}
      symbolInfo={symbolInfo}
      isMobile={isMobile}
      symbolsList={symbolsList}
      accountId={account.id!}
      accountCurrency={account.currency!}
      tradingServerId={account.serverId!}
      chartInfo={chartInfo}
      isChartExpanded={isChartExpanded}
      tickMessage={tickMessage}
      isDarkTheme={isDarkTheme}
      setChartInfo={setChartInfo}
      setIsChartExpanded={setIsChartExpanded}
      lastInterval={lastInterval}
      currencyDecimalScale={account.digits!}
      hasSignal={hasSignal}
      closeSignalChartDialog={closeSignalChartDialog}
      isSignalChartDialogShown={isSignalChartDialogShown}
      openSignalForm={openSignalForm}
      isMobileChartPage={isMobileChartPage}
    />
  );
};

Container.displayName = "ChartContainer";

export { Container as ChartContainer };

import { type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";
import { ScrollArea, Text } from "@/shared/ui";

type Props = {
  title: string;
  subtitle?: string;
  children: ReactNode;
};

const StepWrapper = forwardRef<ElementRef<typeof ScrollArea>, Props>(({ title, subtitle, children }, ref) => {
  return (
    <>
      <div className="mx-auto flex w-full max-w-[512px] flex-col gap-2 px-4">
        <Text as="h2" variant="XL / Medium" color="primary">
          {title}
        </Text>
        {subtitle && (
          <Text as="h3" variant="M / Regular" color="secondary">
            {subtitle}
          </Text>
        )}
      </div>
      <div className="mx-auto mb-[96px] mt-6 min-h-0 w-full max-w-[620px] grow">
        <ScrollArea viewportRef={ref} scrollbars="vertical" viewportClassname="px-4 lg:px-[70px]">
          {children}
        </ScrollArea>
      </div>
    </>
  );
});

const Footer: FC<{ children: ReactNode; isSingleButton?: boolean }> = ({ children, isSingleButton }) => {
  return (
    <div
      className={cn(
        "fixed bottom-0 end-0 start-0 mx-auto flex w-full max-w-[512px] px-4 pb-6 pt-4",
        isSingleButton ? "justify-end" : "justify-between",
      )}
    >
      {children}
    </div>
  );
};

const Component = Object.assign(StepWrapper, {
  Footer,
});

export { Component as StepWrapper };

import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";
import { Label } from "react-aria-components";

import { cn } from "@/shared/styles";

import { _Descriptor } from "./_descriptor";

const inputStyles = cva(
  "has-[input:focus]:border-accent-text has-[input:focus]:bg-input-bg-active has-[label:hover]:bg-input-bg-hover",
  {
    variants: {
      invalid: {
        true: "has-[input:focus]:border-negative-text has-[label:hover]:border-negative-text",
      },
    },
  },
);

const dateStyles = cva(
  "has-[label:hover]:bg-input-bg-hover group-has-[div[data-focus-within]]/input-wrapper:border-accent-text group-has-[div[data-focus-within]]/input-wrapper:bg-input-bg-active group-has-[div[data-focus-within]]/input-wrapper:hover:bg-input-bg-hover",
  {
    variants: {
      invalid: {
        true: "has-[label:hover]:border-negative-text group-has-[div[data-focus-within]]/input-wrapper:border-negative-text",
      },
    },
  },
);

const selectStyles = cva(
  "outline-none hover:bg-input-bg-hover focus:border-accent-text data-[state=open]:border-accent-text",
  {
    variants: {
      invalid: {
        true: "hover:border-negative-bg focus:border-negative-bg data-[state=open]:border-negative-bg",
      },
    },
  },
);

const wrapperStyles = cva(
  "group/select-trigger inline-flex h-12 w-full items-center justify-between gap-3 rounded-md border border-input-border bg-input-bg px-4 text-start text-contrast-secondary transition",
  {
    variants: {
      invalid: {
        true: "border-negative-bg",
      },
      disabled: {
        true: "pointer-events-none bg-input-bg-inactive",
      },
      pending: {
        true: "animate-pulse",
      },
      hasLabel: {
        true: "h-14",
      },
    },
  },
);

type _ExternalWrapperProps = Omit<VariantProps<typeof wrapperStyles>, "hasLabel"> & {
  className?: string;
  label?: ReactNode;
  startSection?: ReactNode;
  endSection?: ReactNode;
  descriptor?: ReactNode;
  floatingLabel?: boolean;
  /**
   * Need to show floating label when input has value
   */
  hasInputValue?: boolean;
};

type Props = ComponentPropsWithoutRef<"div"> &
  _ExternalWrapperProps & {
    type: "input" | "select" | "date";
  };

// TODO: create separates components for input, select and date types
const _Wrapper = forwardRef<ElementRef<"div">, Props>(
  (
    {
      children,
      className,
      startSection,
      endSection,
      disabled,
      pending,
      label,
      invalid,
      descriptor,
      type,
      floatingLabel,
      hasInputValue,
      ...props
    },
    ref,
  ) => {
    const WrapperTag: keyof JSX.IntrinsicElements = type === "select" ? "button" : "div";
    // @ts-ignore
    const LabelTag: keyof JSX.IntrinsicElements = type === "select" ? "div" : type === "date" ? Label : "label";

    const hasLabel = Boolean(label);

    return (
      <div className={cn("group/input-wrapper w-full", className)} data-invalid={invalid} data-disabled={disabled}>
        <WrapperTag
          className={cn(
            wrapperStyles({ disabled, pending, invalid, hasLabel }),
            type === "select" && selectStyles({ invalid }),
            type === "input" && inputStyles({ invalid }),
            type === "date" && dateStyles({ invalid }),
          )}
          {...props}
          // @ts-ignore
          ref={ref}
        >
          {startSection}
          <LabelTag className="relative flex h-full grow flex-col justify-center gap-0.5">
            {hasLabel && (
              <>
                {floatingLabel ? (
                  <>
                    {/* approximately simulates label height */}
                    <div className="h-4" />
                    <div
                      className={cn(
                        "typography-S-Regular absolute w-full origin-top-left truncate text-contrast-secondary transition group-has-[input:focus]/input-wrapper:-translate-y-2.5 group-has-[input:focus]/input-wrapper:scale-[0.85]",
                        {
                          "-translate-y-2.5 scale-[0.85]": hasInputValue,
                          "group-has-[input:focus]/input-wrapper:text-negative-text": invalid,
                        },
                      )}
                    >
                      {label}
                    </div>
                  </>
                ) : (
                  <div
                    className={cn("typography-XS-Regular text-contrast-secondary transition-colors", {
                      "group-has-[input:focus]/input-wrapper:text-negative-text": invalid,
                    })}
                  >
                    {label}
                  </div>
                )}
              </>
            )}
            {children}
          </LabelTag>
          {endSection}
        </WrapperTag>
        {descriptor && <_Descriptor>{descriptor}</_Descriptor>}
      </div>
    );
  },
);

export { _Wrapper };
export type { _ExternalWrapperProps };

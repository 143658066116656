import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import bonusImg from "@/assets/images/bonus.png";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import type { BonusUserPlatform } from "@/services/openapi";
import { Button, Dialog, Image, Text } from "@/shared/ui";

import { getBonusMainPercent } from "../helpers";
import { ExampleTable } from "./example-table";
import { TermsBlock } from "./terms-block";

type Props = {
  bonus: BonusUserPlatform;
};

const BonusDialog: FC<Props> = ({ bonus }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const mainPercent = useMemo(() => getBonusMainPercent(bonus), [bonus]);
  const isDepositPage = location.pathname.includes(cabinetRoutes.deposit);

  return (
    <Dialog.Content size="lg">
      <Dialog.Header
        centered
        title={t("bonus.dialog.title", { value: mainPercent })}
        image={
          <Dialog.Image size="lg">
            <Image src={bonusImg} />
          </Dialog.Image>
        }
      />
      <div className="flex flex-col gap-6">
        <Text align="center" variant="S / Regular" color="primary">
          <Trans
            i18nKey="bonus.dialog.description"
            values={{ value: mainPercent }}
            components={{
              strong: <strong className="typography-S-Medium" />,
            }}
          />
        </Text>
        <ExampleTable currency={bonus.currency!} mainPercent={mainPercent!} />
        <div className="h-px w-full bg-contrast-quinary" />
        <TermsBlock bonus={bonus} />
      </div>
      <Dialog.Buttons>
        {isDepositPage ? (
          <Dialog.Close asChild>
            <Button>{t("button.deposit")}</Button>
          </Dialog.Close>
        ) : (
          <Dialog.Close asChild>
            <Button asChild>
              <Link to={cabinetRoutes.deposit}>{t("button.deposit")}</Link>
            </Button>
          </Dialog.Close>
        )}
      </Dialog.Buttons>
    </Dialog.Content>
  );
};

export { BonusDialog };

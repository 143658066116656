import { cva } from "class-variance-authority";
import { type FC, useMemo } from "react";
import { Trans } from "react-i18next";

import type { OnboardingStep } from "@/features/onboarding/helpers";
import { cn } from "@/shared/styles";
import { Text } from "@/shared/ui";

const stepStyles = cva("h-full transition-all", {
  variants: {
    state: {
      default: "bg-contrast-quaternary",
      filled: "bg-surface-elevation-3",
      transparent: "bg-transparent",
    },
  },
  defaultVariants: {
    state: "default",
  },
});

type Props = {
  steps: OnboardingStep[];
  currentStepId: string;
};

const ProgressBar: FC<Props> = ({ currentStepId, steps }) => {
  const stepsAmount = steps.length;

  const currentStepIndex = useMemo(() => steps.findIndex(step => step.id === currentStepId), [currentStepId, steps]);

  return (
    <div className="mx-auto mb-2 w-full max-w-[512px] px-4">
      <Text variant="S / Medium">
        <Trans
          i18nKey="onboarding.progress-bar"
          values={{
            stepCount: currentStepIndex + 1,
            stepsAmount,
          }}
        />
      </Text>

      <div className="py-2.5">
        <div className="relative h-1 w-full overflow-hidden rounded-full bg-contrast-quaternary">
          {steps.map((step, index) => {
            const isCurrent = step.id === currentStepId;
            const isFilled = step.filled;
            let stepClass = "";

            if (index < currentStepIndex && step.filled) {
              stepClass = stepStyles({ state: "filled" });
            } else if (isCurrent) {
              stepClass = stepStyles({
                state: isFilled ? "filled" : "default",
              });
            } else if (index === currentStepIndex + 1) {
              stepClass = stepStyles({ state: "transparent" });
            }

            if (stepClass) {
              return (
                <div
                  key={step.id}
                  className={cn(stepClass, "absolute start-0 top-0")}
                  style={{
                    width: `${((index + 1) / stepsAmount) * 100}%`,
                  }}
                />
              );
            }

            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export { ProgressBar };

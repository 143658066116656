import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import positiveImg from "@/assets/images/positive.png";
import { getDepositRoute } from "@/routes/cabinet.routes";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { TradingAccountType } from "@/services/openapi";
import { Button, Dialog, Image, Text } from "@/shared/ui";

type Props = {
  accountId: string;
  accountType: TradingAccountType;
  onDetailsSelect: () => void;
  onClose: () => void;
};

const CreateAccountResultMT5Intro: FC<Props> = ({ accountId, accountType, onDetailsSelect, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        centered
        title={t("accounts.create.dialog.title")}
        image={
          <Dialog.Image size="md">
            <Image src={positiveImg} />
          </Dialog.Image>
        }
      />
      <Text variant="S / Regular" color="primary" align="center">
        {t("accounts.create.dialog.mt5-description")}
      </Text>
      <Dialog.Buttons direction="column">
        {accountType === TradingAccountType.Real ? (
          <Button onClick={onClose} asChild>
            <Link to={getDepositRoute(accountId)}>{t("accounts.buttons.deposit")}</Link>
          </Button>
        ) : (
          <Button onClick={onClose} asChild>
            <Link to={getTerminalRoute(accountId)}>{t("accounts.buttons.trade")}</Link>
          </Button>
        )}
        <Button variant="secondary" onClick={onDetailsSelect}>
          {t("accounts.common.mt-login-details", { version: 5 })}
        </Button>
      </Dialog.Buttons>
    </>
  );
};

export { CreateAccountResultMT5Intro };

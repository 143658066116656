import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import verifyWhiteImg from "@/assets/images/verify-white.png";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { Button, Dialog, Image } from "@/shared/ui";

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

const ExitOnboardingDialog: FC<Props> = ({ open, onOpenChange }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.Header
          title={t("onboarding.dialog.title")}
          centered
          image={
            <Dialog.Image size="lg">
              <Image src={verifyWhiteImg} />
            </Dialog.Image>
          }
        />
        <Dialog.Description>{t("onboarding.dialog.description")}</Dialog.Description>
        <Dialog.Buttons direction="column">
          <Dialog.Close asChild>
            <Button>{t("onboarding.dialog.continue-button")}</Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button asChild variant="secondary">
              <Link to={getTerminalRoute("")}>{t("onboarding.dialog.explore-button")}</Link>
            </Button>
          </Dialog.Close>
        </Dialog.Buttons>
      </Dialog.Content>
    </Dialog>
  );
};

export { ExitOnboardingDialog };

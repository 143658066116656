import SumsubWebSdk from "@sumsub/websdk-react";
import type { FC } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { logError } from "@/app/libs/sentry";
import { DialogName, getDialogSearchParams } from "@/hooks/dialog.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { ExternalKycStatus, type SumSubInfo } from "@/services/openapi";
import { profileQueryKeys, useProfileHeaderQuery } from "@/state/server/profile";
import { theme } from "@/styles";

import { useSumSub } from "./sumsub.helpers";

const customCssStr: string = `button.submit \n {\n background: ${theme.colors.primary.value};\n}\n button.submit:hover {\nbackground: ${theme.colors.primary.value} !important;\n} button.submit:active {\nbackground: ${theme.colors.primary.value} !important;\n}`;

type Props = {
  sumSubInfo: SumSubInfo;
  from: string | null;
};

const SumsubContainer: FC<Props> = ({ sumSubInfo, from }) => {
  const client = useQueryClient();
  const { refetch: refetchProfile } = useProfileHeaderQuery();
  const navigate = useNavigate();
  const { data, expirationHandler } = useSumSub(sumSubInfo.levelNames![0] ? sumSubInfo.levelNames![0].sumSubName! : "");

  const onDone = useCallback(async () => {
    const { data: profile } = await refetchProfile();

    if (profile!.kycStatus === ExternalKycStatus.Approved) {
      if (from) {
        navigate(from);
        return;
      }
      navigate({
        pathname: cabinetRoutes.dashboard,
        search: getDialogSearchParams(DialogName.CONGRATULATIONS),
      });
    } else {
      if (from) {
        navigate(from);
        return;
      }

      navigate({
        pathname: cabinetRoutes.dashboard,
        search: getDialogSearchParams(DialogName.VERIFICATION),
      });
    }
  }, [from, navigate, refetchProfile]);

  return (
    <div>
      {data && (
        // https://developers.sumsub.com/web-sdk/#introduction
        <SumsubWebSdk
          accessToken={data.accessToken!}
          expirationHandler={expirationHandler}
          onMessage={(type: any) => {
            if (type === "idCheck.onApplicantSubmitted") {
              client.invalidateQueries(profileQueryKeys.header());
              onDone();
            }

            /* if (message.type === "idCheck.applicantStatus") {
              const applicantStatus = message.payload.applicantStatus;

              if (applicantStatus === "approved") {
                console.log("Documents successfully verified!");
                // Здесь вызываем вашу функцию
                yourCustomFunction();
              } else if (applicantStatus === "declined") {
                console.error("Documents were declined.");
              }
            } */

            console.log(type);

            if (type === "idCheck.onApplicantStatusChanged" && type?.reviewStatus === "completed") {
              client.invalidateQueries(profileQueryKeys.header());
              onDone();
            }
          }}
          config={{
            uiConf: {
              customCssStr,
            },
          }}
          onError={(error: any) =>
            logError(error, {
              tags: {
                sumsub: true,
              },
            })
          }
        />
      )}
    </div>
  );
};

export { SumsubContainer };

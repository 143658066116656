import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

import positiveImg from "@/assets/images/positive.png";
import { DialogName, dialogParamsName, useDialog } from "@/hooks/dialog.hook";
import { Button, Dialog, Image } from "@/shared/ui";
import { profileQueryKeys } from "@/state/server/profile";
import { useConfirmUserEmailMutation } from "@/state/server/profile/profile.mutations";

const ConfirmEmailDialog = () => {
  const { t } = useTranslation();

  const { onClose, open } = useDialog(DialogName.EMAIL_CONFIRM);

  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const { isSuccess, mutate } = useConfirmUserEmailMutation();

  const userId = searchParams.get("userId")!;
  const code = searchParams.get("code")!;
  const dialog = searchParams.get(dialogParamsName)!;

  useEffect(() => {
    if (userId && code && dialog === DialogName.EMAIL_CONFIRM) {
      mutate({
        confirmUpdateEmailRequest: {
          code,
          userId,
        },
      });
    }
  }, [userId, code, dialog]);

  if (!isSuccess) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        onClose();
        queryClient.invalidateQueries(profileQueryKeys.header());
      }}
    >
      <Dialog.Content>
        <Dialog.Header
          title={t("profile-settings.confirm-email.title")}
          centered
          image={
            <Dialog.Image size="md">
              <Image src={positiveImg} />
            </Dialog.Image>
          }
        />
        <Dialog.Buttons>
          <Dialog.Close asChild>
            <Button>{t("profile-settings.confirm-email.close-button")}</Button>
          </Dialog.Close>
        </Dialog.Buttons>
      </Dialog.Content>
    </Dialog>
  );
};

export { ConfirmEmailDialog };

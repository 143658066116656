import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { ScrollRestoration, useNavigate } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { getTerminalRoute } from "@/routes/terminal.routes";
import type { KycInfo, ProfileHeader } from "@/services/openapi";

import { OnboardingHeader } from "./components/onboarding-header/header";
import { ProfileStep } from "./components/steps/profile/profile-step";
import { SurveyContainer } from "./components/steps/survey/survey.container";
import { SurveyWrapper } from "./components/survey-wrapper";
import { createOnboardingSteps, NavigationTitle } from "./helpers";

type Props = {
  profile: ProfileHeader;
  kycInfo: KycInfo;
  from: string | null;
};

const Onboarding: FC<Props> = ({ kycInfo, profile, from }) => {
  const navigate = useNavigate();

  const steps = useMemo(() => createOnboardingSteps(kycInfo, profile), [kycInfo, profile]);

  const [activeStep, setActiveStep] = useState(() => {
    const firstUnfilledStep = steps.find(({ filled }) => !filled);

    if (firstUnfilledStep) {
      return firstUnfilledStep.id;
    }
    return steps[0]!.id;
  });

  const activeStepCount = useMemo(() => steps.findIndex(({ id }) => id === activeStep) + 1, [activeStep, steps]);

  const surveyGroup = useMemo(
    () => kycInfo.survey!.surveyGroups!.find(({ id }) => id === activeStep),
    [activeStep, kycInfo],
  );

  const onBack = useCallback(() => {
    const currentStepIndex = activeStepCount - 1;
    setActiveStep(steps[currentStepIndex - 1]!.id);
  }, [activeStepCount, steps]);

  const onNext = useCallback(() => {
    if (activeStepCount < steps.length) {
      const nextStep = steps[activeStepCount]!;
      track(amplitudeEvents.kyc.next(activeStepCount));
      setActiveStep(nextStep.id);
      return;
    }

    if (profile.featuresInfo!.mandatorySurvey) {
      if (from) {
        navigate(from);
        return;
      }
      navigate(getTerminalRoute(""));
      return;
    }

    navigate(onboardingRoutes.kyc);
  }, [activeStepCount, from, navigate, profile.featuresInfo, steps]);

  return (
    <>
      <ScrollRestoration key={surveyGroup?.id} />
      <OnboardingHeader
        activeStepCount={activeStepCount}
        email={profile.email!}
        isSurveyCompleted={profile.options!.isSurveyCompleted!}
        isSurveyMandatory={profile.featuresInfo!.mandatorySurvey!}
        from={from}
      />

      <SurveyWrapper currentStepId={steps[activeStepCount - 1]!.id} steps={steps}>
        {activeStep === NavigationTitle.PROFILE ? (
          <ProfileStep profile={kycInfo.profile!} address={kycInfo.address!} onNext={onNext} />
        ) : (
          <SurveyContainer
            isLastStep={activeStepCount === steps.length}
            surveyGroup={surveyGroup!}
            onBack={onBack}
            onSubmit={onNext}
          />
        )}
      </SurveyWrapper>
    </>
  );
};

export { Onboarding };

import type { FC } from "react";

import { Text, UnstyledButton } from "@/shared/ui";

import { LanguageSwitcherContainer } from "./container";

type Props = {
  onOpen: () => void;
};

export const LanguageSwitcherButton: FC<Props> = ({ onOpen }) => {
  return (
    <LanguageSwitcherContainer>
      {({ icon, name }) => (
        <UnstyledButton
          // FIXME: non-existing border radius
          className="flex shrink-0 items-center gap-2.5 whitespace-nowrap rounded-[40px] border-none bg-none px-3 py-5 text-black transition-all"
          onClick={onOpen}
          data-test="language-switcher-button"
        >
          <img className="size-6 rounded-full" src={icon!} alt={name!} />
          <Text variant="S / Regular" color="primary">
            {name}
          </Text>
        </UnstyledButton>
      )}
    </LanguageSwitcherContainer>
  );
};

import type { FC } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { GlobalLoader } from "@/components/loader";
import { Onboarding } from "@/features/onboarding/onboarding";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useKycInfoQuery } from "@/state/server/kyc";
import { useProfileData } from "@/state/server/profile/profile.hooks";

const OnboardingPage: FC = () => {
  const profile = useProfileData();

  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");

  const { data: kycInfo, isLoading: isKycInfoLoading } = useKycInfoQuery();

  if (isKycInfoLoading) {
    return <GlobalLoader />;
  }

  if (profile.options!.isSurveyCompleted) {
    return <Navigate to={cabinetRoutes.dashboard} />;
  }

  return <Onboarding kycInfo={kycInfo!} profile={profile} from={from} />;
};

export { OnboardingPage };

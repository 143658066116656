import type { FC } from "react";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { toggleLiveChat } from "@/app/libs/intercom";
import logoDark from "@/assets/images/LogoDark.svg";
import { Box } from "@/components/box";
import { DepositIcon } from "@/components/icons";
import { useAuth } from "@/contexts/auth.context";
import { LanguageSwitcherButton, LanguageSwitcherContainer } from "@/features/language-switcher";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { authRoutes } from "@/routes/auth.routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { styled } from "@/styles/stitches.config";
import { SidebarButton } from "@/widgets/sidebar/button";

import { Button } from "../button";
import { Flex } from "../flex";
import { ExternalLink as Link } from "../link";
import { Link as ReactLink } from "../link";
import { Text } from "../text";
import { headerStyles, headerWrapperStyles } from "./styles";

const Wrapper = styled("div", headerWrapperStyles);

const StyledHeader = styled("header", headerStyles);

const Ul = styled("ul", {
  display: "flex",
  gap: "40px",
});

const NavItem: FC<{ link: string; title: string }> = ({ link, title }) => (
  <li>
    <Link href={link}>
      <Text color="black" weight="bold">
        {title}
      </Text>
    </Link>
  </li>
);

const HeaderContent: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const { isMobile, isLargeDesktop } = useScreenSize();
  const isSignInPage = pathname === authRoutes.signIn;

  const [open, setOpen] = useState(false);

  const authenticatedContent = useMemo(
    () => (
      <>
        <NavItem link={cabinetRoutes.dashboard} title={t("cabinet.navigation.accounts")} />
        <NavItem link={cabinetRoutes.deposit} title={t("cabinet.navigation.deposit")} />
        <NavItem link={cabinetRoutes.withdrawal} title={t("cabinet.navigation.withdrawal")} />
        <NavItem link={cabinetRoutes.transfer} title={t("cabinet.navigation.transfer")} />
        <li>
          <Text color="black" weight="bold" css={{ cursor: "pointer" }} onClick={toggleLiveChat}>
            {t("cabinet.navigation.support")}
          </Text>
        </li>
      </>
    ),
    [t],
  );

  const unAutheticatedContent = useMemo(
    () => (
      <>
        <li>
          <Link href="https://doto.com/markets">
            <Text color="black" weight="bold">
              {t("auth.navigation.markets")}
            </Text>
          </Link>
        </li>
        <li>
          <Link href="https://doto.com/platforms">
            <Text color="black" weight="bold">
              {t("auth.navigation.trading")}
            </Text>
          </Link>
        </li>
        <li>
          <Link href="https://doto.com/about">
            <Text color="black" weight="bold">
              {t("auth.navigation.company")}
            </Text>
          </Link>
        </li>
      </>
    ),
    [],
  );

  const navigationContent = useMemo(() => {
    if (isAuthenticated) {
      return authenticatedContent;
    } else {
      return unAutheticatedContent;
    }
  }, [authenticatedContent, isAuthenticated, unAutheticatedContent]);

  return (
    <>
      <Flex css={{ alignItems: "center" }}>
        {isAuthenticated && isMobile && (
          <Box css={{ mr: "18px" }}>
            <SidebarButton />
          </Box>
        )}
        <ReactLink to={isAuthenticated ? cabinetRoutes.dashboard : authRoutes.signIn}>
          <img alt="doto" src={logoDark} height="28" width="102" />
        </ReactLink>
        {isLargeDesktop && (
          <nav className="hidden lg:ms-20 lg:block">
            <Ul>{navigationContent}</Ul>
          </nav>
        )}
      </Flex>
      <Flex css={{ gap: "16px", alignItems: "center" }}>
        {isLargeDesktop && (
          <>
            <LanguageSwitcherButton onOpen={() => setOpen(true)} />
            <LanguageSwitcherContainer open={open} onOpenChange={setOpen} />
          </>
        )}

        {!isAuthenticated ? (
          <ReactLink to={isSignInPage ? authRoutes.signUp : authRoutes.signIn}>
            <Button variant="secondary" size="small">
              {isSignInPage ? t("auth.sign-up.sign-up") : t("auth.sign-in.login")}
            </Button>
          </ReactLink>
        ) : (
          <ReactLink to={cabinetRoutes.deposit} target="_blank" rel="noreferrer">
            <Button size="small" variant="secondary" leftIcon={<DepositIcon />}>
              {t("button.deposit")}
            </Button>
          </ReactLink>
        )}
      </Flex>
    </>
  );
};

/**
 * @deprecated
 */
export const Header: FC = () => {
  return (
    <Wrapper>
      <StyledHeader>
        <HeaderContent />
      </StyledHeader>
    </Wrapper>
  );
};

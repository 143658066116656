import { Provider as TooltipProvider } from "@radix-ui/react-tooltip";
import type { FC, ReactNode } from "react";
import { Suspense } from "react";
import { I18nProvider } from "react-aria";
import { ReactQueryDevtools } from "react-query/devtools";

import { AuthContextProvider } from "@/contexts/auth.context";
import { ThemeContextProvider } from "@/contexts/theme.context";
import { IconChevronDown, IconChevronUp, IconCross, IconTick } from "@/domains/icons";
import { IconProvider } from "@/domains/icons/icon.provider";
import { useInitializeLanguage } from "@/i18n/helpers";
import { Toaster } from "@/shared/ui";
import { ReactQueryProvider } from "@/state/server";

import { InitialDataProvider } from "./initial-data.provider";

interface Props {
  children: ReactNode;
}

export const AppProvider: FC<Props> = ({ children }) => {
  const { raLocale } = useInitializeLanguage();

  return (
    <I18nProvider locale={raLocale}>
      <ThemeContextProvider>
        <IconProvider
          icons={{
            IconCross: () => <IconCross />,
            IconChevronDown: () => <IconChevronDown />,
            IconChevronUp: () => <IconChevronUp />,
            IconTick: () => <IconTick />,
          }}
        >
          {/* <Suspense fallback={<GlobalLoader />}> */}
          <Suspense fallback={<></>}>
            <TooltipProvider delayDuration={0} skipDelayDuration={100}>
              <ReactQueryProvider>
                {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
                <InitialDataProvider>
                  <AuthContextProvider>{children}</AuthContextProvider>
                </InitialDataProvider>
                <Toaster />
              </ReactQueryProvider>
            </TooltipProvider>
          </Suspense>
        </IconProvider>
      </ThemeContextProvider>
    </I18nProvider>
  );
};

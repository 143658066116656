import Big from "big.js";
import { cva } from "class-variance-authority";
import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { cn } from "@/shared/styles";
import { Text } from "@/shared/ui";

const cellStyles = cva("typography-S-Regular p-2 first:ps-0 last:pe-0")();
const labelStyles = cva([cellStyles, "text-start text-contrast-secondary"])();
const valueStyles = cva([cellStyles, "text-end text-contrast-primary"])();

const values = {
  amount: 1000,
  lots: 0.5,
  profit: 500,
};

type Props = {
  currency: string;
  mainPercent: number;
};

const ExampleTable: FC<Props> = ({ currency, mainPercent }) => {
  const { t } = useTranslation();

  const valuesAfterBonus: typeof values = useMemo(() => {
    const multiplier = new Big(mainPercent).div(100).plus(1);
    return {
      amount: new Big(values.amount).mul(multiplier).toNumber(),
      lots: new Big(values.lots).mul(multiplier).toNumber(),
      profit: new Big(values.profit).mul(multiplier).toNumber(),
    };
  }, [mainPercent]);

  return (
    <div className="flex flex-col gap-4">
      <Text align="center" color="primary" variant="S / Medium">
        <Trans
          i18nKey="bonus.dialog.example-table.caption"
          values={{ currency }}
          components={{
            value: <NumberFormat value={values.amount} />,
          }}
        />
      </Text>
      <div className="rounded-xl border border-card-border bg-card-bg-accent px-6 py-5">
        <table className="w-full">
          <tr className="border-b border-contrast-quinary">
            <td className={labelStyles}></td>
            <td className={valueStyles}>{t("bonus.dialog.example-table.no-bonus")}</td>
            <td className={valueStyles}>{t("bonus.dialog.example-table.bonus-value", { value: mainPercent })}</td>
          </tr>
          <tr className="border-b border-contrast-quinary">
            <td className={labelStyles}>{t("bonus.dialog.example-table.balance")}</td>
            <td className={valueStyles}>
              <NumberFormat prefix="$" value={values.amount} decimalScale={2} />
            </td>
            <td className={cn(valueStyles, "text-positive-text")}>
              <NumberFormat prefix="$" value={valuesAfterBonus.amount} decimalScale={2} />
            </td>
          </tr>
          <tr className="border-b border-contrast-quinary">
            <td className={labelStyles}>{t("bonus.dialog.example-table.volume")}</td>
            <td className={valueStyles}>{t("bonus.dialog.example-table.lot", { value: values.lots })}</td>
            <td className={cn(valueStyles, "text-positive-text")}>
              {t("bonus.dialog.example-table.lot", { value: valuesAfterBonus.lots })}
            </td>
          </tr>
          <tr>
            <td className={labelStyles}>{t("bonus.dialog.example-table.profit")}</td>
            <td className={valueStyles}>
              <NumberFormat prefix="+$" value={values.profit} decimalScale={2} />
            </td>
            <td className={cn(valueStyles, "text-positive-text")}>
              <NumberFormat prefix="+$" value={valuesAfterBonus.profit} decimalScale={2} />
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export { ExampleTable };

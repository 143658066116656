import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, memo } from "react";

import { cn } from "@/shared/styles";

const textStyles = cva("block", {
  variants: {
    variant: {
      inherit: "typography-inherit",
      "3XL / Regular": "typography-3XL-Regular",
      "3XL / Medium": "typography-3XL-Medium",
      "2XL / Regular": "typography-2XL-Regular",
      "2XL / Medium": "typography-2XL-Medium",
      "XL / Regular": "typography-XL-Regular",
      "XL / Medium": "typography-XL-Medium",
      "L / Regular": "typography-L-Regular",
      "L / Medium": "typography-L-Medium",
      "M / Regular": "typography-M-Regular",
      "M / Medium": "typography-M-Medium",
      "M Compact / Regular": "typography-M-Compact-Regular",
      "M Compact / Medium": "typography-M-Compact-Medium",
      "S / Regular": "typography-S-Regular",
      "S / Medium": "typography-S-Medium",
      "S Compact / Regular": "typography-S-Compact-Regular",
      "S Compact / Medium": "typography-S-Compact-Medium",
      "XS / Regular": "typography-XS-Regular",
      "XS / Medium": "typography-XS-Medium",
    },
    color: {
      inherit: "text-inherit",
      primary: "text-contrast-primary",
      secondary: "text-contrast-secondary",
      tertiary: "text-contrast-tertiary",
      negative: "text-negative-text",
      warning: "text-warning-text",
      positive: "text-positive-text",
      staticWhite: "text-static-white",
      staticBlack: "text-static-black",
    },
    inline: {
      true: "inline",
    },
    align: {
      start: "text-start",
      center: "text-center",
      end: "text-end",
    },
  },
});

type Props = ComponentPropsWithoutRef<"p"> &
  VariantProps<typeof textStyles> & {
    as?:
      | "h1"
      | "h2"
      | "h3"
      | "h4"
      | "h5"
      | "h6"
      | "p"
      | "span"
      | "em"
      | "strong"
      | "small"
      | "b"
      | "cite"
      | "i"
      | "dt"
      | "dd"
      | "td"
      | "th"
      | "div"
      | "li";
    asChild?: boolean;
  };

const Text = forwardRef<ElementRef<"p">, Props>(
  (
    {
      children,
      className,
      asChild,
      as: Tag = "p",
      variant = "inherit",
      color = "inherit",
      inline,
      align = "start",
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <Slot className={cn(textStyles({ variant, color, inline, align }), className)} {...props} ref={forwardedRef}>
        {asChild ? children : <Tag>{children}</Tag>}
      </Slot>
    );
  },
);

const Component = memo(Text);

Component.displayName = "Text";

type TextColor = ComponentPropsWithoutRef<typeof Text>["color"];

export { Component as Text };
export type { TextColor };

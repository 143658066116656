import Big from "big.js";
import type { FC } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { useTerminalAccountContext } from "@/features/terminal/contexts/account.context";
import { useSymbolsContext } from "@/features/terminal/contexts/symbols.context";
import { TerminalTableState, useLayoutContext } from "@/features/terminal/layout/context";
import { useCurrentSymbolContext } from "@/features/terminal/symbol-info/current-symbol-context";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useLocalStorage } from "@/hooks/localstorage.hook";
import {
  TerminalDealExpirationType,
  TerminalDealFillPolicy,
  TerminalDealType,
  TradingAccountType,
} from "@/services/openapi";
import { useOpenTerminalOrderMutation } from "@/state/server/terminal";

import { useSignalContext } from "../signal/context";
import { usePlaceOrderContext } from "./context";
import { PlaceOrderForm } from "./form";
import { getOriginalVolume, getPlaceOrderType } from "./place-order.helpers";
import { StopOutDialog } from "./stop-out/stop-out-dialog";

const PlaceOrderContainer: FC = () => {
  const { symbolInfo } = useCurrentSymbolContext();

  const [hideStopOutDialog, setHideStopOutDialogLS] = useLocalStorage("hideStopOutDialog", false);
  const [showStopOutDialog, { open: openStopOutDialog, close: closeStopOutDialog }] = useDisclosure();

  const { changeTable, onDeposit, isMobile } = useLayoutContext();

  const {
    volumeMode,
    volumeLotsFormValue,
    volumeMarginFormValue,
    volumeLots,
    volumeMargin,
    changeLots,
    changeMargin,
    maxBalanceVolumeLots,
    maxBalanceVolumeMargin,
    maxSystemVolumeLots,
    minSystemVolumeLots,
    maxSystemVolumeMargin,
    minSystemVolumeMargin,
    maxAvailableVolumeLots,
    maxAvailableVolumeMargin,
    volumeLotsDecimalScale,
    volumeMarginDecimalScale,
    hasNoFreeMargin,
    volumeLotsError,
    openPrice,
    realTimeMargin,
    ask,
    bid,
    currency,
    changeVolumeMode,
    direction,
    changeDirection,
    openPriceFormValue,
    openPriceOnBlur,
    changeOpenPrice,
    changeIsPendingOrder,
    isPendingOrder,
    currentPrice,
    isBuyOrder,
    takeProfitEnabled,
    changeStopLossEnabled,
    changeTakeProfitEnabled,
    stopLossEnabled,
    changeStopLoss,
    changeTakeProfit,
    stopLoss,
    stopLossFormValue,
    takeProfit,
    takeProfitFormValue,
    stopLossError,
    takeProfitError,
    takeProfitThresholdValue,
    stopLossThresholdValue,
    hasErrors,
    changeSliderValue,
    sliderValue,
    onSliderCommit,
    sliderDisabled,
    volumeOnBlur,
    resetForm,
    isSignal,
    signal,
    marginFree,
  } = usePlaceOrderContext();

  const { closeSignalForm } = useSignalContext();

  const {
    account: { id: accountId, leverage, type },
  } = useTerminalAccountContext();

  const { symbols } = useSymbolsContext();

  const {
    digits,
    symbol,
    contractSize,
    baseCurrency,
    type: instrumentType,
    quoteCurrency,
    marginRateInitialMarketBuy,
  } = symbolInfo;

  const { mutateAsync: openOrder } = useOpenTerminalOrderMutation();

  const handleSubmit = (showDialog: boolean = true) => {
    if (hasNoFreeMargin) {
      onDeposit();
      return Promise.resolve();
    }

    if (hasErrors) {
      return Promise.resolve();
    }

    // if volume more than 50% of free margin
    if (!hideStopOutDialog && showDialog && volumeMargin && new Big(volumeMargin).mul(2).gt(marginFree)) {
      openStopOutDialog();
      track(amplitudeEvents.terminal.openStopOutRiskDialog);
      return Promise.resolve();
    }

    const type = getPlaceOrderType({ ask, bid, openPrice, isBuyOrder, isPendingOrder });

    return openOrder(
      {
        tradingAccountId: accountId!,
        terminalOpenOrderRequest: {
          volume: volumeLots!,
          type,
          symbol,
          expirationType: isPendingOrder ? TerminalDealExpirationType.Gtc : undefined,
          fillPolicy:
            type === TerminalDealType.BuyLimit || type === TerminalDealType.SellLimit
              ? TerminalDealFillPolicy.Return
              : undefined,
          price: isPendingOrder ? openPrice : undefined,
          takeProfit: takeProfitEnabled ? takeProfit : undefined,
          stopLoss: stopLossEnabled ? stopLoss : undefined,
          volumeOriginal: getOriginalVolume({ volumeMode, volumeLots, volumeMargin }),
          signalType: isSignal ? signal?.type : undefined,
        },
      },
      {
        onSuccess: () => {
          resetForm();

          closeSignalForm();
          if (!isMobile) {
            if (isPendingOrder) {
              changeTable(TerminalTableState.PENDING);
              return;
            }
            changeTable(TerminalTableState.OPEN);
          }
        },
      },
    );
  };

  return (
    <>
      <PlaceOrderForm
        isSignal={isSignal}
        isMobile={isMobile}
        volumeOnBlur={volumeOnBlur}
        maxAvailableVolumeLots={maxAvailableVolumeLots}
        maxAvailableVolumeMargin={maxAvailableVolumeMargin}
        sliderDisabled={sliderDisabled}
        onSliderCommit={onSliderCommit}
        changeSliderValue={changeSliderValue}
        sliderValue={sliderValue}
        stopLoss={stopLoss}
        stopLossThresholdValue={stopLossThresholdValue}
        takeProfitThresholdValue={takeProfitThresholdValue}
        takeProfit={takeProfit}
        stopLossError={stopLossError}
        takeProfitError={takeProfitError}
        changeStopLoss={changeStopLoss}
        stopLossFormValue={stopLossFormValue}
        changeTakeProfit={changeTakeProfit}
        takeProfitFormValue={takeProfitFormValue}
        stopLossEnabled={stopLossEnabled}
        changeStopLossEnabled={changeStopLossEnabled}
        takeProfitEnabled={takeProfitEnabled}
        changeTakeProfitEnabled={changeTakeProfitEnabled}
        currentPrice={currentPrice}
        isBuyOrder={isBuyOrder}
        realTimeMargin={realTimeMargin}
        isPendingOrder={isPendingOrder}
        changeIsPendingOrder={changeIsPendingOrder}
        changeOpenPrice={changeOpenPrice}
        openPriceOnBlur={openPriceOnBlur}
        openPriceFormValue={openPriceFormValue}
        direction={direction}
        changeDirection={changeDirection}
        marginRateInitialMarketBuy={marginRateInitialMarketBuy!}
        isDemoAccount={type === TradingAccountType.Demo}
        changeVolumeMode={changeVolumeMode}
        leverage={leverage!}
        hasNoFreeMargin={hasNoFreeMargin}
        volumeMode={volumeMode}
        changeLots={changeLots}
        changeMargin={changeMargin}
        onSubmit={handleSubmit}
        priceDecimalScale={digits!}
        ask={ask}
        bid={bid}
        contractSize={contractSize!}
        baseCurrency={baseCurrency!}
        currency={currency}
        volumeLots={volumeLots}
        volumeMargin={volumeMargin}
        maxSystemVolumeLots={maxSystemVolumeLots}
        maxBalanceVolumeLots={maxBalanceVolumeLots}
        minSystemVolumeLots={minSystemVolumeLots}
        maxBalanceVolumeMargin={maxBalanceVolumeMargin}
        maxSystemVolumeMargin={maxSystemVolumeMargin}
        minSystemVolumeMargin={minSystemVolumeMargin}
        volumeLotsError={volumeLotsError}
        volumeMarginDecimalScale={volumeMarginDecimalScale}
        volumeLotsDecimalScale={volumeLotsDecimalScale}
        instrumentType={instrumentType!}
        symbols={symbols}
        quoteCurrency={quoteCurrency!}
        volumeLotsFormValue={volumeLotsFormValue}
        volumeMarginFormValue={volumeMarginFormValue}
        onDeposit={onDeposit}
      />
      <StopOutDialog
        showStopOutDialog={showStopOutDialog}
        hideStopOutDialog={hideStopOutDialog}
        setHideStopOutDialog={setHideStopOutDialogLS}
        closeStopOutDialog={closeStopOutDialog}
        onSubmit={() => handleSubmit(false)}
      />
    </>
  );
};

export { PlaceOrderContainer };

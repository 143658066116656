import { cva } from "class-variance-authority";
import { type FC, type HTMLAttributeAnchorTarget, memo, type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

import { Logo } from "@/app/components";
import { useLocationPath } from "@/app/hooks";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { toggleLiveChat } from "@/app/libs/intercom";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { getTerminalRoute, isCurrentPageTerminal } from "@/routes/terminal.routes";
import { Button, UnstyledButton } from "@/shared/ui";
import { useIsReadOnly } from "@/state/server/profile/profile.hooks";

const linkStyles = cva(
  "typography-S-Medium rounded-full px-3 py-1 text-contrast-secondary outline-none transition-colors hover:text-contrast-primary focus-visible:text-contrast-primary focus-visible:outline-2 focus-visible:outline-accent-text active:text-contrast-primary",
  {
    variants: {
      active: {
        true: "!text-contrast-primary",
      },
    },
  },
);

type Props = {
  hideTrading?: boolean;
  blankLinks?: boolean;
  children?: ReactNode;
};

const Header: FC<Props> = ({ children, hideTrading = false, blankLinks = false }) => {
  const { t } = useTranslation();

  const location = useLocation();

  const isReadOnly = useIsReadOnly();

  const currentLocation = useLocationPath();

  const isTerminalActive = useMemo(() => isCurrentPageTerminal(location.pathname), [location.pathname]);

  const target: HTMLAttributeAnchorTarget | undefined = blankLinks ? "_blank" : void 0;

  return (
    <header className="flex h-header-height shrink-0 items-center justify-between bg-surface-canvas pe-4 ps-6">
      <div className="flex items-center gap-10">
        <div className="flex items-center gap-4">
          <Logo />
          {/* TODO: theme switch */}
        </div>
        <div className="flex items-center gap-2">
          {!hideTrading && (
            <Link
              className={linkStyles({
                active: isTerminalActive,
              })}
              to={isTerminalActive ? currentLocation : getTerminalRoute("")}
              data-test="navlink-terminal"
              target={target}
            >
              {t("cabinet.navigation.terminal")}
            </Link>
          )}
          <NavLink
            className={({ isActive }) => linkStyles({ active: isActive })}
            to={cabinetRoutes.dashboard}
            data-test="navlink-accounts"
            target={target}
          >
            {t("cabinet.navigation.accounts")}
          </NavLink>
          <NavLink
            className={({ isActive }) => linkStyles({ active: isActive })}
            to={cabinetRoutes.profileSettings}
            data-test="navlink-profile-settings"
            target={target}
          >
            {t("cabinet.navigation.profile-settings")}
          </NavLink>
          <UnstyledButton data-test="navlink-support" onClick={toggleLiveChat} className={linkStyles()}>
            {t("cabinet.navigation.support")}
          </UnstyledButton>
        </div>
      </div>
      <div className="flex items-center gap-6">
        {children}
        {!isReadOnly && (
          <div className="flex items-center gap-3">
            <Button size="sm" variant="secondary" asChild>
              <Link to={cabinetRoutes.withdrawal} data-test="navlink-withdrawal" target={target}>
                {t("cabinet.navigation.withdraw-button")}
              </Link>
            </Button>
            <Button
              size="sm"
              onClick={isTerminalActive ? () => track(amplitudeEvents.deposit.fromTerminal) : void 0}
              asChild
            >
              <Link to={cabinetRoutes.deposit} data-test="navlink-deposit" target={target}>
                {t("cabinet.navigation.deposit")}
              </Link>
            </Button>
          </div>
        )}
      </div>
    </header>
  );
};

const Component = memo(Header);

export { Component as Header };

import Big from "big.js";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import CheckBottom from "@/features/payment/assets/check-bottom.svg";
import type { Field } from "@/services/openapi";
import { DataList, Image, Skeleton, Text } from "@/shared/ui";

type Props = {
  login: number;
  paymentMethod: {
    title: string;
    img: string;
  };
  amount: number;
  currency: string;
  fee: number | undefined;
  bonusBalance: number;
  additionalFields?: Field[] | null;
  decimalScale: number;
  credit: number;
};

const cutWalletString = (input: string, regexp: string | null): string => {
  if (input.length <= 8 || !regexp) {
    return input;
  }

  const result = input.match(regexp);

  return `${result![1]}...${result![2]}`;
};

const Check: FC<Props> = ({
  login,
  paymentMethod,
  amount,
  currency,
  fee,
  bonusBalance,
  additionalFields,
  decimalScale,
  credit,
}) => {
  const { t } = useTranslation();
  const isFeeLoaded = fee !== void 0 && fee !== null;
  const total = isFeeLoaded ? new Big(amount).minus(fee).toNumber() : null;

  return (
    <>
      <div className="rounded-t-2xs bg-surface-elevation-2 px-4 pb-1 pt-[30px] shadow">
        <DataList>
          <DataList.Item
            label={<DataList.Label>{t("payments.check.account-id")}</DataList.Label>}
            value={<DataList.Value>{login}</DataList.Value>}
          />
          <DataList.Item
            label={<DataList.Label>{t("payments.payment-method")}</DataList.Label>}
            value={
              <DataList.Value>
                <div className="flex items-center gap-1">
                  <Image src={paymentMethod.img} className="size-4" /> {paymentMethod.title}
                </div>
              </DataList.Value>
            }
          />
          {additionalFields?.map(({ id, title, value, valueDisplayRegex }) => {
            return (
              <DataList.Item
                key={id}
                label={<DataList.Label>{title}</DataList.Label>}
                value={
                  <DataList.Value>
                    {id === "purse" ? cutWalletString(value!, valueDisplayRegex!) : value}
                  </DataList.Value>
                }
              />
            );
          })}
          <DataList.Item
            label={<DataList.Label>{t("payments.check.amount.withdrawal")}</DataList.Label>}
            value={
              <DataList.Value>
                <NumberFormat value={amount} currency={currency} decimalScale={decimalScale} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("payments.check.fee")}</DataList.Label>}
            value={
              <DataList.Value>
                {isFeeLoaded ? (
                  <NumberFormat value={fee} currency={currency} decimalScale={decimalScale} />
                ) : (
                  <Skeleton className="h-5 w-14" />
                )}
              </DataList.Value>
            }
          />
          {!!bonusBalance && (
            <DataList.Item
              label={<DataList.Label>{t("payments.check.bonus.withdrawal")}</DataList.Label>}
              value={
                <DataList.Value>
                  {!!credit && (
                    <Text variant="inherit" color="tertiary" className="line-through" inline>
                      <NumberFormat value={credit} decimalScale={decimalScale} />
                    </Text>
                  )}{" "}
                  <NumberFormat value={bonusBalance} decimalScale={decimalScale} currency={currency} />
                </DataList.Value>
              }
            />
          )}
          <DataList.Item
            label={<DataList.Label>{t("payments.check.total")}</DataList.Label>}
            value={
              <DataList.Value>
                {isFeeLoaded ? (
                  <NumberFormat value={total} currency={currency} decimalScale={decimalScale} />
                ) : (
                  <Skeleton className="h-5 w-14" />
                )}
              </DataList.Value>
            }
          />
        </DataList>
      </div>
      <Image className="w-full max-w-full" src={CheckBottom} />
    </>
  );
};

export { Check };
